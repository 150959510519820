.navbarHeading {
    color: white;
    font-weight: bold;
    font-family: "Futura", sans-serif;
  }
  
  
  /* Import FutureMedium from ../../ fonts */
  @font-face {
    font-family: "FuturaMedium";
    src: local("FuturaMedium"), url(../../fonts/FuturaMedium.ttf) format("truetype");
  }
  
  /* Import FuturaBold from  */
  @font-face {
    font-family: "FuturaBold";
    src: local("FuturaBold"), url(../../fonts/FuturaBold.ttf) format("truetype");
  }
  
  .gradient-background {
    background: linear-gradient(rgb(255, 255, 255), rgb(222, 222, 215));
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  
  .content {
    flex-grow: 1;
    overflow-y: auto;
  }
  
  
  
  
  
  .navigationHeading {
    font-family: "FuturaMedium", sans-serif;
    font-weight: bold;
  }
  
  .navbar-toggler-icon {
    /* Add any styling for the toggler icon */
  }
  
  .navigation-content {
    font-family: "FuturaMedium", sans-serif;
  }
  
  
  
  
  .socials-container img {
    width: 2rem;
    height: 2rem;
    margin: 5px;
  }
  
  .socials-container {
    display: flex;
  }
  
  .socials-container img {
    margin-right: 10px;
  }
  
  .banner-image {
    width: 100%;
    height: auto;
    object-fit: cover; /* Crop the image to cover the container */
  }
  
  
  
  .footer-text {
    text-align: center;
    color: #838383;
    font-size:10px;
    margin-top: 50px;
    margin-bottom:20px;
  }
  .nav-header{
    margin-left:56px;
    font-family: "FuturaBold", sans-serif;
    font-weight: bold;
    color: white;
    font-size: 1rem;
  }
  
  .banner-container {
    width: 100%;
  }
  
  .banner-container img {
    width: 100%;
    height: auto;
  }
  .navbar-container {
    background-color: #FFCF36;
  }
  .red-text {
    color: rgb(223, 39, 74);
    font-family: "FuturaBold", sans-serif !important;
  }
  
  
  .new-praise-along-nav{
    background-color: #FFCF36;
  }
  .breaker-line{
    margin: 0 !important;
  }
  .socials-container {
    display: flex;
    align-items: center;
    margin-right: 10px; /* Adjust the spacing between the social images and the text */
  }
  
  .socials-container img {
    width: 2rem;
    height: 2rem;
    margin-right: 5px; /* Adjust the spacing between the social images */
  }
  .content {
    flex-grow: 1;
    overflow: auto; /* Add overflow property to enable scrolling if content exceeds the container */
    padding: 0 10px; /* Add padding to create spacing between content and container edges */
    box-sizing: border-box; /* Include padding within the total width of the container */
  }
  
  
  /* Media query for <664 pixels */
  @media (max-width: 600px) {
    .nav-header{
      display:none;
    }
    
  }
  body {
    margin: 0;
    padding: 0;
  }
  
  .landing-page-image{
    width:100%;
    height:auto;
    /* Center image */
    
  }
  
  .container {
    width: 100%;
    max-width: 1200px; /* Adjust the maximum width as per your requirements */
    margin: 0 auto; /* Center the container horizontally */
  }
  
  
  /* Media < 850px */
  @media (max-width: 849px) {
    .banner-image{
     /* Normal object-fit */
      object-fit: contain;
      padding: 0 50px 0 50px;
    }
  }
  .homepage-content{
    /* FuturaMedium */
    font-family: "FuturaMedium", sans-serif;
    /* Color 63 91 139 */
    color: rgb(63, 91, 139);
    text-align: center;;
  }
  .download-button button{
    /* center horizontally button */
    display: block;
    margin-left: auto;
    margin-right: auto;
    
  }
  .button-text{
    font-family: "FuturaBold", sans-serif;
  
  
  }
  
  
  .download-button {
    /* center horizontally */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .background-image {
    position: absolute;
    width: 200px;
    height: 125px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none; /* Add this line */
  }
  
  
  .button-text {
    font-family: "FuturaBold", sans-serif;
  }
  
  
  .trans-white-bg{
    /* 40% transparent white */
    background-color: rgba(255, 255, 255, 0.4);
    padding: 5rem 0 5rem 0;
  }
  
  .footer-image {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: -1
    ;
  
  }
  
  .full-width-image {
    width: 100%;
    height: auto;
  }
  
  
  .red-ccli-text{
    color: #DF274A;
    font-family: "FuturaMedium", sans-serif;
  }
  
  .blue-ccli-text{
    color: #2A59B4;
    font-family: "FuturaMedium", sans-serif;
  }
  .form-group-container{
    margin-top: 5rem !important;
  }
  
  .futura-bold-font {
    font-family: "FuturaBold", sans-serif;
  }
  
  .privacy-header{
    font-family: "FuturaBold", sans-serif;
    color:  rgb(231,104,100);
    font-size: 1.6rem;
  }
.privacy-subheader{
    color:  rgb(231,104,100);
    font-family: "FuturaBold", sans-serif;
    margin-top: 5px;
}
.privacy-content{
    color: rgb(63, 91, 139);
    font-family: "Calibri", sans-serif;
    padding: 0 0 20px 0;
}

.about-header{
    font-family: "FuturaBold", sans-serif;
    color:  rgb(231,104,100);
}
.about-content {
  color: rgb(63, 91, 139);
  font-family: "FuturaMedium", sans-serif;
  margin-top: 5px;
  text-align: center;
}

.k-fort-image{
  width: 45%;
  height: auto;
}

.calendar-image{
  width:100%;
  height:auto;
}