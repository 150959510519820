
/* Import FutureMedium from ../../ fonts */
@font-face {
    font-family: "FuturaMedium";
    src: local("FuturaMedium"), url(../../fonts/FuturaMedium.ttf) format("truetype");
  }
  
.new-praise-along-nav{
    /* Font family FuturaMedium */
    font-family: "FuturaMedium", sans-serif !important;
    
}
.futura-medium-font{
    /* Font family FuturaMedium */
    font-family: "FuturaMedium", sans-serif !important;
}
.futura-bold{
    /* Font family FuturaBold */
    font-family: "FuturaBold", sans-serif !important;
color: rgb(231,104,100);
}
.nav-title{
    color: white;
}
.nav-highlight{
    color: rgb(231, 104, 100);
}

.red-text-coming-up-nav {
  font-family: "FuturaBold", sans-serif !important;
  display: inline;
}
@media (max-width: 700px) {
    .nav-title {
        display: none;
    }
}
