.App {
  text-align: center;
}
body {
  font-size: 1.2rem; /* 1rem is equal to the root font size (16px) */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
html,body{

}

#sign-in-container{
  width: 100%;
  padding: 5rem;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

.logo-image{
  width:20rem;
  height:auto;
}
.logo-image-post-jan{
  width:20rem;
  height:auto;
}


.praise-along-video{
  height: auto;
  margin: auto;
}


.text-center{
  color:rgb(31,160,162);
}
label{
  margin:10px;
}


#loginButton{
  bottom:10px;
}



.praise-along-nav{
  background-color: rgb(255) !important;
  z-index: 1;
  height:8rem;
}


.content-container{
  background-color: rgb(201,195,190);
}

#videoContainer{
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/*  230 228 225 */

#registerForm{
  background-color: rgb(230,228,225) !important;
  padding:20px;
  margin-bottom:2rem;

  line-height: 2rem;
  color: rgb(66, 93, 140);
}

#registerContents{
  line-height: 2rem;
  margin-bottom: 2rem;
  color: rgb(66, 93, 140);

}

.copyright-container{
  font-family: 'Arial', sans-serif;
  font-size:12px;
}

.praise-along-blue{
  color: rgb(66, 93, 140);
  text-align: left;;
}
/* 66 93 140 */

#formBasicCheckbox{
  margin-top:18px;
}

@media screen and (max-width:800px) {
  .logo-image{
    display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  }
  .logo-image-post-jan{
    display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  }
 }



.copyright-container{
  text-align: right;
  margin-bottom:2rem;
  color:rgb(120, 120, 120)
 }

 .grey-bg{
  background-color: rgb(201,195,190);

 }


 #registerForm{
  padding:20px;
 }
.grey-bg{
  padding:20px;
}

.contact-us{
  font-size: 20px;
  margin-top: 10px;
}
#noMarginCheckbox{
  margin-left: 0 !important;
}

.testing{
  z-index:1 !important;
  position:absolute !important;
  height:100%;
}

.front{
  position: relative;
  z-index:100 !important;
  
}

.imageRow{
  margin: 20%;
}
.imagerow img{
  width:20px;
  height:20px;
}

.imageRowImages{
  position: absolute;
  width:auto;
  height:10%;
  text-align: center;
}


#formBasicDiocese{
  color: rgb(108,117,125);
}

.white-bg{
  /* White background */
  background-color : white;

}
.tab-image{
  width:8vw;
  height: 5.333vw;
}



.bold-font{
  font-family: Futura;
  font-weight: bold;
}
.soft-font{
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}


.myColumn {
  float: left;
  padding: 0;
}
.myRow:after {
  content: "";
  display: table;
  clear: both;
}


/* media for when window < 766px */
@media screen and (max-width: 766px) {
  .tab-image{
    width:12vw !important;
    height: 8vw !important;
  }
  
}